import React from "react"
import { Component } from "react"
import { htmlRenderer } from "../utils/htmlRenderer"



class ShortText extends Component {
    render() {

        const { content, limit } = this.props;
        const limitNum = parseInt(limit)
        const string = htmlRenderer(content)
        // In the final case, we show a text with ellipsis and a `Read more` button
        var ellipsis = string.length > limitNum ? "..." : "";
        const toShow = string.slice(0, limitNum)
        return <div className="text">
            {toShow}{ellipsis}
        </div>
    }
}

export default ShortText