import ReactHtmlParser from 'react-html-parser';


const parser = function (content) {

    var text = ""
    content.forEach(element => {
        if (!element.props) {
            text = text + element + " ";
        }
        else if (element.props.children) {
            text = text + parser(element.props.children) + " ";
        }

    });
    return text;
}

export const htmlRenderer = content => {
    return parser(ReactHtmlParser(content))
}
